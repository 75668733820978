// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("/opt/build/repo/src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-aboutyou-js": () => import("/opt/build/repo/src/pages/projects/aboutyou.js" /* webpackChunkName: "component---src-pages-projects-aboutyou-js" */),
  "component---src-pages-projects-oasa-js": () => import("/opt/build/repo/src/pages/projects/oasa.js" /* webpackChunkName: "component---src-pages-projects-oasa-js" */),
  "component---src-pages-projects-otto-js": () => import("/opt/build/repo/src/pages/projects/otto.js" /* webpackChunkName: "component---src-pages-projects-otto-js" */),
  "component---src-pages-projects-sinnerschrader-js": () => import("/opt/build/repo/src/pages/projects/sinnerschrader.js" /* webpackChunkName: "component---src-pages-projects-sinnerschrader-js" */),
  "component---src-pages-projects-volkswagen-js": () => import("/opt/build/repo/src/pages/projects/volkswagen.js" /* webpackChunkName: "component---src-pages-projects-volkswagen-js" */),
  "component---src-pages-projects-xing-js": () => import("/opt/build/repo/src/pages/projects/xing.js" /* webpackChunkName: "component---src-pages-projects-xing-js" */),
  "component---src-pages-projects-zalando-js": () => import("/opt/build/repo/src/pages/projects/zalando.js" /* webpackChunkName: "component---src-pages-projects-zalando-js" */)
}

